



































































































































































































.container {
  > div {
    &:nth-child(1) {
      display: flex;
      justify-content: center;
      background-color: #fff;
      overflow: hidden;
      vertical-align: middle;
      > div:nth-child(1) {
        img {
          display: block;
        }
      }
      > div:nth-child(2) {
        padding: 10px 30px 0;
        color: #333;

        > h4 {
          font-size: 30px;
        }

        > p {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &:nth-child(2) {
      margin-top: 20px;

      > ul {
        > li {
          padding: 30px 0;

          > div {
            &:nth-child(1) {
              display: flex;
              align-items: center;

              > i {
                display: inline-block;
                width: 80px;
                height: 80px;
                line-height: 80px;
                color: #000096;
                font-size: 40px;
                font-style: normal;
                text-align: center;
                text-indent: -20px;
                background: url("../assets/img/fwzc_khfw_icon.png") no-repeat center;
              }

              > span {
                font-weight: bold;
                font-size: 24px;
                color: #000096;
                margin-left: 15px;
                height: 80px;
                display: flex;
                align-items: center;
              }
            }

            &:nth-child(2) {
              > p {
                font-size: 18px;
                color: #333;
                position: relative;
                left: 80px;
              }
            }
          }
        }
      }
    }

    .sale-cn {
      > div {
        &:nth-child(1) {
          text-align: center;

          > h3 {
            font-size: 44px;
            color: #000096;
            font-weight: normal;
          }

          > span {
            display: inline-block;
            font-size: 26px;
            color: #666;
          }
        }

        &:nth-child(2) {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;

          > div {
            &:nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              position: relative;
              right: 60px;

              > i {
                width: 90px;
                height: 90px;
                display: inline-block;
                border-radius: 50%;
                cursor: default;

                &:nth-child(1) {
                  background: #000096 url("../assets/img/tel_white.png") no-repeat center;
                }

                &:nth-child(2) {
                  background: #000096 url("../assets/img/email.png") no-repeat center;
                }
              }
            }

            &:nth-child(3) {
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              > span {
                font-size: 30px;
                color: #333;
                white-space: nowrap;
              }
            }
          }
        }

        &:nth-child(3) {
          margin-top: 50px;
          background: #eee;
          display: flex;
          justify-content: space-around;
          color: #333;
          padding: 50px 0;

          > div {
            flex: 1;
            &:nth-child(1) {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              > h4 {
                font-size: 34px;
                font-weight: normal;
                text-align: center;
              }

              > select{
                position: relative;
                width: 80%;
                left: 10%;
                height: 60px;
                line-height: 60px;
                border: 1px #ccc solid;
                font-size: 20px;
                display: flex;
                padding-left: 3%;
                outline: none;
                appearance: none;
                background: url("../assets/img/select_arrow.png") no-repeat 98% 50%;
              }
            }

            &:nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              > span {
                font-size: 20px;
                line-height: 40px;
              }
            }
          }
        }
      }
    }

    .sale-en {
      display: flex;
      justify-content: space-between;
      > div {
        &:nth-child(1) {
          color: #333;
          background-color: #F5F5F5;
          padding: 30px 0 30px 80px;
          flex: 1;

          > h4 {
            font-size: 30px;
            line-height: 60px;
          }

          > p {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .container {
    >div:nth-child(1){
      >div:nth-child(1){
        display: none;
      }
      >div:nth-child(2){
        h4 {
          font-size: 20px;
        }
      }
    }
    >div:nth-child(2){
      >ul{
        >li{
          padding-left: 12px;
          >div:nth-child(1){
            >i{
              width: 38px;
              height: 45px;
              left: -36px;
              top: 15px;
              line-height: 45px;
              font-size: 25px;
              padding-right: 11px;
              background-size: cover;
              text-indent:0;
            }
          }
          >div:nth-child(2){
            width: calc(100% - 20px);
            >p {
              padding: 0 15px;
              left: 20px;
            }
          }
        }
      }
    }
    >div:nth-child(3){
      >div:nth-child(2){
        flex-wrap: wrap;
        >div:nth-child(1) {
          width: 100%;
          padding: 0 15px;
          >img {
            width: 100%;
          }
        }
        >div:nth-child(2){
          >i{
            position: relative;
            left: 55px;
            width: 35px;
            height: 35px;
            background-size: 20px !important;
          }
        }
        >div:nth-child(3){
          margin-right: auto;
          position: relative;
          left: 30px;
          >span{
            font-size: 18px;
          }
        }
      }
      >div:nth-child(3){
        display: block;
        >div:nth-child(2){
          text-align: center;
        }
      }
    }
    .sale-en{
      flex-wrap: wrap;
      >div:nth-child(1){
        padding:30px 0 30px 30px !important;
      }
      >div:nth-child(2){
        img{
          width: 100%;
        }
      }
    }
  }
}
