





























































































































































.container {
  > form {
    > div {
      > h4 {
        text-align: center;
        font-size: 18px;
        color: #333;
        font-weight: normal;
        margin-bottom: 20px;
      }

      > div {
        ::v-deep > label {
          font-size: 15px;
          color: #333;
          position: relative;
          right: 15px;
        }
      }

      .hint {
        font-size: 15px;
        color: #737373;
        font-style: italic;
      }
    }
  }

  > div {
    &:nth-child(2) {
      position: relative;
      z-index: 100;
      text-align: center;

      > button {
        margin: 0 20px;
      }
    }
  }
}
@media (max-width: 767px) {
  .container{
    padding: 0 15px;
  }
  ::v-deep .el-form-item__label {
    left: 10px !important;
    text-align:left !important;
  }
  ::v-deep .el-form-item__content{
    margin-left: 10px !important;
  }
}
