






































































.qrcode-wrap {
  display: flex;
  justify-content: space-around;
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
   img {
    width: 160px;
    height: 160px;
   }
  }
}
.container {

  > ul {
    > li {
      > h3 {
        font-size: 22px;
        color: #333;
        padding-left: 3%;
        border-left: 6px #000096 solid;
        font-weight: normal;
        margin: 30px 0;
      }

      > ul {
        > li {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          color: #666666;
          line-height: 26px;
          padding: 7px 0 7px 3%;
          position: relative;

          .btn-hover {
            cursor: pointer;
          }

          > a {
            margin-left: 50px;
          }

          .btn-download {
            margin-left: auto;
            margin-right: 20px;
          }
          > a:hover {
            color: #000096;
          }


          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #000096;
            top: 17px;
            left: 0;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
