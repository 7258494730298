

















































































































































.container{
  > div{
    &:nth-child(1){
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &:nth-child(2){
      margin-top: 30px;
    }

    .pagination {
      margin-top: 50px;
      > .container {
        justify-content: center;
      }
    }
  }
}
@media (max-width: 767px) {
  .tab-list{
    >.container{
      >div:nth-child(1){
        display: block;
        ::v-deep .el-input{
          width: 100% !important;
          padding: 0 30px 15px 30px ;
          box-sizing: border-box;
        }
        ::v-deep .el-button{
          width: calc(100% - 60px) !important;
          height: 34px;
          margin: 0 30px;
        }
      }
    }
  }
}
