

























































































section{
  main {
    padding-top: 70px;
    padding-bottom: 60px;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;

    .tab-list-wrapper {
      width: 1200px;
      margin: 0 auto;
    }
   
    .tab-list {
      margin: 102px 0;
    }
  }
}
@media (max-width: 767px) {
  section{
    main {
      width: 100% ;
      .tab-list-wrapper {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}
